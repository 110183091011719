.bg-box{
    position: relative;
    background-image: url(../../public/hero_overlay.png);
    width: 100%;
    z-index: 1000;
    left:0;
    top:0;    
}
html,body{
    overflow-x: hidden;
}

.fes-box{
    position: relative;
    left: 0;
    top:-65px;
    z-index: 1000;
}
.svg-image:hover {
    transform: rotateY(180deg); 
}
.svg-image{
    transition: transform 0.5s;
}

 .accordion-button{
    font-size: 21px;
    font-weight: 600;
}
.contact-bg{
    background-image: url('../../public/real-1.jpg');
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position:  center;
    height: 600px;
    position: relative;
    background-attachment: fixed;

}
/* Success message sending mail */
.success-message {
    background-color: #4CAF50; /* Green background color */
    color: white; /* White text color */
    padding: 10px; /* Padding around the message */
    border-radius: 4px; /* Rounded corners */
    margin-bottom: 10px; /* Add some space below the message */
    text-align: center; /* Center the text */
    font-size: 16px; /* Adjust the font size as needed */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Add a shadow for emphasis */
  }
  
  /* Add a transition for smooth appearance and disappearance */
  .success-message {
    transition: opacity 0.5s, transform 0.5s;
  }
  
  /* Define the style when it's hidden */
  .success-message.hidden {
    opacity: 0;
    transform: translateY(-20px);
    pointer-events: none; /* Prevent interactions with hidden message */
  }
/* hover effect on carousel */
  
.con-box1 {
   
    width: 50%;
  }
  
  .image-1{
    height: 310px !important;
  }
  
  .overlay-1{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #008CBA;
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: .5s ease;
    z-index: 99999;
  }
  
  .con-box1:hover .overlay-1 {
    height: 310px !important;
  }
  
  .text-2{
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 99999;
  }


/* hover effect on carousel */
.home-brige{
    background-image: url('../../public/breadcumb-bg.jpg');
    width: 100%;
    height:400px;
    
}
.breadcrumb-item+.breadcrumb-item::before{
    color:#fff
}
.project-svg{
    width:80px;
    height:80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0px 10px 50px rgba(30,40,58,0.12);

}
.svg{
    transition: transform 0.5s;
}
.svg:hover{
    transform: rotateY(180deg); 
}
.navbar-toggler{
    background-color: #fff;

}
.whatsapp-button {
    position: fixed;
    bottom: 30px; 
    right: 22px; 
    background-color: #4bbb74;
    border: none;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    box-shadow: 0px 4px 8px 5px rgba(0, 0, 0, 0.2);
    z-index: 1111; 
  }
  
  .whatsapp-button img {
    width: 50px; 
    height: 50px;
    border-radius: 50%;
  }


@media(max-width:600px){
    .contact-bg{
        height: auto;
    }
    .mob-res{
        position: static !important;
        width: 100% !important;
        

    }
    .nav-mob{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .bg-img{
        height: 251px;
    }
    .about-more{
        flex-direction: column; 
    }
    .about-box{
        margin-right: 0 !important;
    }
    .phone-icon{
        font-size: 30px !important;
    }
    .img-vision{
        width:48px;
    }
    .con-res{
        padding: 2px 7px !important;
    }
    .home-brige{
        height:250px !important
    }
}

@media(max-width:767px){
    .quote-box{
            height: 21.5vh !important;
    }
}
@media(min-width:991px){
    #nav-id{
        
        justify-content: end !important;
    }
}